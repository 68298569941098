import React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"

import {
  H4,
  fontFamily,
  color,
  mq,
  width,
  widthFromGrid,
} from "../../utils/styles"

type Props = {
  className?: string
}
const Intro: React.FC<Props> = props => {
  return (
    <section
      css={css`
        ${mq.pc(css`
          display: flex;
          justify-content: space-between;
          width: ${widthFromGrid()}px;
          margin: 144px auto 0;
        `)}
        ${mq.sp(css`
          max-width: ${width.spContentMax};
          width: 94%;
          margin: 62px auto 0;
        `)}
      `}
      {...props}
    >
      <div
        css={css`
          flex: 0 0 ${widthFromGrid(6)}px;
        `}
      >
        <div
          css={css`
            border-bottom: 1px solid ${color.pink};
            ${mq.pc(css`
              padding-bottom: 16px;
            `)}
            ${mq.sp(css`
              padding: 16px 0 8px;
            `)}
          `}
        >
          <H4 css={css``}>代表挨拶</H4>
          <h6
            css={css`
              font-family: ${fontFamily.serif};
              font-weight: bold;
              ${mq.pc(css`
                padding-top: 8px;
                font-size: 2rem;
                line-height: 1.7;
              `)}
              ${mq.sp(css`
                padding-top: 8px;
                font-size: 1.4rem;
                line-height: 1.6;
              `)}
            `}
          >
            代表取締役　山田達人
          </h6>
        </div>
        <p
          css={css`
            ${mq.pc(css`
              margin-top: 24px;
            `)}
            ${mq.sp(css`
              max-width: ${width.spContentMax};
              margin-top: 16px;
            `)}
            }
          `}
        >
          八丈島で生まれ育ち、都内の食品メーカーへ就職したのち、25歳のときに家業を継ぐ形で島へ戻ってきました。
          <br />
          島内、島外の両方の場所での生活の経験から、八丈島には何があり、何が足りないのかを考えてきました。
          <br />
          八丈島のみならず、東京の島々の価値あるローカルアイランドフードの発信と販売、全国の素晴らしいローカルフードを八丈島に仕入れ販売することで、島での暮らしが少しでも豊かになるよう歩んでまいります。
        </p>
        <h5
          css={css`
            font-size: 1.6rem;
            ${mq.pc(css`
              margin-top: 24px;
            `)}
            ${mq.sp(css`
              max-width: ${width.spContentMax};
              margin-top: 16px;
            `)}
            }
          `}
        >
          来歴
        </h5>
        <p
          css={css`
            ${mq.pc(css`
              margin-top: 8px;
            `)}
            ${mq.sp(css`
              max-width: ${width.spContentMax};
              margin-top: 8px;
            `)}
            }
          `}
        >
          - 八丈島の三根地区に生まれる
          <br />
          - 八丈島の小学校〜八丈高校を卒業
          <br />
          - 流通販売業の専門学校を卒業
          <br />
          - 都内の大手食品メーカーに就職
          <br />
          - 山田屋を継ぐため八丈島に帰島
        </p>
      </div>
      <StaticImage
        css={css`
          ${mq.pc(css`
            flex: 0 0 ${widthFromGrid(5)}px;
          `)}
          ${mq.sp(css`
            display: block;
            max-width: 420px;
            margin: 32px auto 0;
          `)}
        `}
        src="../../img/company/owner.jpg"
        alt="オーナー"
      />
    </section>
  )
}

export default Intro
