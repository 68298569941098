import React from "react"
import { css } from "@emotion/react"


const GoogleMap: React.FC = (props) => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13366.046853348314!2d139.78936366867077!3d33.12191867995468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601571be16f72ebb%3A0xa127a396c252d4c4!2z5p2x5Lqs5bO26YWS5bCC6ZaA5bqXIOWxseeUsOWxiw!5e0!3m2!1sja!2sjp!4v1628573187677!5m2!1sja!2sjp"
      loading="lazy"
      css={css`
        border: 0;
        width: 100%;
        height: 556px;
      `}
      {...props}
    ></iframe>
  )
}

export default GoogleMap
