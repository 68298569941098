import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

import { color, mq, width, fontFamily } from "../../utils/styles"
import { ReactComponent as LogoMark } from "../../img/common/logo.svg"
import { ReactComponent as IconTwitter } from "../../img/common/icon_twitter.svg"
import { ReactComponent as IconInstagram } from "../../img/common/icon_Instagram.svg"
import { ReactComponent as IconFacebook } from "../../img/common/icon_facebook.svg"
import BusinessHourNote from "../common/business-hour-note"

const Footer: React.FC = () => {
  return (
    <footer
      css={css`
        background: ${color.black};
        ${mq.pc(css`
          margin-top: 350px;
          padding: 40px 0 80px;
        `)}
        ${mq.sp(css`
          margin-top: 120px;
          padding: 60px 0 120px;
        `)}
      `}
    >
      <div
        css={css`
          margin: 0 auto;
          ${mq.pc(css`
            display: grid;
            grid-template-columns: 1fr repeat(12, 69px) 1fr;
            grid-template-rows: auto;
            gap: 24px 16px;
          `)}
          ${mq.sp(css`
            width: 94%;
            max-width: ${width.spContentMax};
            margin: 0 auto;
          `)}
        `}
      >
        <div
          css={css`
            position: relative;
            ${mq.pc(css`
              grid-column: 11 / -1;
            `)}
            ${mq.sp(css``)}
          `}
        >
          <div
            css={css`
              overflow: hidden;
              ${mq.pc(css`
                width: 100%;
                height: 446px;
                position: absolute;
                top: -200px;
                left: 0;
              `)}
              ${mq.sp(css`
                width: 180px;
                height: 180px;
                margin: 0 auto;
              `)}
            `}
          >
            <LogoMark
              css={css`
                ${mq.pc(css`
                  width: 446px;
                  max-width: auto;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                `)}
                ${mq.sp(css`
                  display: none;
                `)}
              `}
            />
            <Link
              to="/"
              css={css`
                ${mq.pc(css`
                  display: none;
                `)}
              `}
            >
              <LogoMark />
            </Link>
          </div>
        </div>
        <h2
          css={css`
            font-family: ${fontFamily.serif};
            ${mq.pc(css`
              grid-column: 2/ 6;
              grid-row: 1;
              line-height: 1;
            `)}
            ${mq.sp(css`
              text-align: center;
            `)}
          `}
        >
          <span
            css={css`
              font-size: 1.1rem;
            `}
          >
            東京島酒・自然派ワインの
          </span>
          <br />
          <Link
            to="/"
            css={css`
              text-decoration: none;
              font-size: 5.2rem;
              line-height: 1.4;
            `}
          >
            山田屋
          </Link>
        </h2>

        <div
          css={css`
            ${mq.pc(css`
              grid-column: 2/ 6;
              grid-row: 2;
            `)}
            ${mq.sp(css`
              display: block;
              width: fit-content;
              margin: 32px auto 0;
            `)}
          `}
        >
          〒100-1511
          <br />
          東京都八丈島八丈町三根1952-1
          <br />
          <a href="mailto:info@8jo-yamadaya.co.jp">info@8jo-yamadaya.co.jp</a>
          <p
            css={css`
              margin-top: 16px;
              font-size: 1.3rem;
              letter-spacing: 0.2em;
            `}
          >
            <Link to="/privacy">PRIVACY POLICY</Link>
          </p>
        </div>

        <div
          css={css`
            ${mq.pc(css`
              grid-column: 6/ 11;
              grid-row: 2;
            `)}
            ${mq.sp(css`
              display: block;
              width: fit-content;
              margin: 32px auto 0;
            `)}
          `}
        >
          <table
            css={css`
              font-size: 2.4rem;
              ${mq.pc(css`
                tr > th {
                  padding-right: 1em;
                }
              `)}
              ${mq.sp(css`
                tr,
                th,
                td {
                  text-align: left;
                  display: block;
                }
              `)}
            `}
          >
            <tbody>
              <tr>
                <th>営業時間</th>
                <td>火〜日：10:00～19:00</td>
              </tr>
              <tr>
                <th></th>
                <td>
                  月曜日：定休日
                </td>
              </tr>
            </tbody>
          </table>
          <BusinessHourNote />
        </div>

        <ul
          css={css`
            list-style: none;
            display: flex;
            ${mq.pc(css`
              grid-column: 2/ 6;
              grid-row: 3;
            `)}
            ${mq.sp(css`
              margin: 32px auto 0;
              justify-content: center;
            `)}
            & > li {
              width: 37px;
              height: 37px;
              & + li {
                margin-left: 24px;
              }
              & > a {
                display: block;
              }
              svg {
                flex: 0 0 100%:
                height: auto;
              }
            }
          `}
        >
          <li>
            <a
              href="https://twitter.com/yamadaya8jo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconTwitter />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/8joyamadaya/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconInstagram />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/8jo.yamadaya/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconFacebook />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
