import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { color, mq, width, widthFromGrid, fontFamily } from "../utils/styles"

import Layout from "../components/layout"
import SubpageTitle from "../components/common/subpage-title"
import LinkButton from "../components/common/link-button"

const NotFound: React.FC = () => {
  return (
  <Layout>
      <SubpageTitle title="404 NOT FOUND" />
      <section
        css={css`
          background: ${color.white};
          color: ${color.black};
          text-align: center;
          ${mq.pc(css`
            width: ${widthFromGrid()}px;
            margin: 80px auto 0;
            padding: 80px 0 120px;
          `)}
          ${mq.sp(css`
            max-width: ${width.spContentMax};
            width: 94%;
            margin: 48px auto 0;
            padding: 16px 8px 32px;
          `)}
        `}
      >
        <p>
          お探しのページは見つかりませんでした。
        </p>
        <LinkButton to="/"
          css={css`
            margin: 24px auto 0;
            `}
        >トップページに戻る</LinkButton>
      </section>
  </Layout>
  )
}

export default NotFound
