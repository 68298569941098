import React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import {color as csxColor} from "csx"
import {useLocation} from "@reach/router"

import {
  color,
  widthFromGrid,
  mq,
  fontFamily,
} from "../../utils/styles"
import { ReactComponent as LogoMark } from "../../img/common/logo.svg"
import { ReactComponent as ExternalLink } from "../../img/common/ex_link.svg"

const NavSide = styled.ul`
  list-style: none;
  flex: 1;
  display: flex;
  align-items: center;
  font-family: ${fontFamily.serif};
  ${mq.sp(css`
    display: none;
  `)}
  & > li + li {
    margin-left: 40px;
  }
  a {
    pointer-events: auto;
    text-decoration: none;
  }
`

type HeaderProps = {
  spnavOpen: boolean;
  setSpnavOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const Header: React.FC<HeaderProps> = ({spnavOpen, setSpnavOpen}) => {
  const location = useLocation().pathname
  return (
    <>
      <header
        className={spnavOpen?"spnavOpen":""}
        css={css`
          position: fixed;
          pointer-events: none;
          z-index: 1000;
          margin: 0 auto;
          ${mq.pc(css`
            inset: 24px 0 auto;
            width: ${widthFromGrid()}px;
            filter: drop-shadow(0 0 3px ${csxColor(color.black).fade(0.8).toString()});
          `)}
          ${mq.sp(css`
            width: 94%;
            inset: 8px 0 auto;
          `)}
        `}
      >
        <nav
          id="gnav"
          css={css`
            ${mq.pc(css`
              display: flex;
            `)}
            ${mq.sp(css`
              visibility: ${location === '/'? 'collapse': 'inherit'};
            `)}
          `}
        >
          <NavSide
            css={css`
              justify-content: flex-end;
            `}
          >
            <li>
              <Link to="/about">山田屋について</Link>
            </li>
            <li>
              <Link to="/philosophy">島焼酎への想い</Link>
            </li>
            <li>
              <Link to="/company">会社概要</Link>
            </li>
          </NavSide>
          <Link
            to="/"
            css={css`
              pointer-events: auto;
              ${mq.pc(css`
                flex: 0 0 80px;
                margin: 0 40px;
              `)}
              ${mq.sp(css`
                display: block;
                width: 32px;
                margin: 0 auto;
                svg {
                  vertical-align: bottom;
                }
              `)}
            `}
          >
            <LogoMark
              css={css`
                ${mq.sp(css`
                filter: drop-shadow(0 0 3px ${csxColor(color.black).fade(0.3).toString()});
                `)}
              `}
            />
          </Link>
          <NavSide>
            <li>
              <Link to="/recruit">採用情報</Link>
            </li>
            <li>
              <Link to="/contact">お問い合わせ</Link>
            </li>
            <li>
              <a href="https://www.shimarket.tokyo/" target="_blank" rel="noopener noreferrer">
                オンラインストア
                <ExternalLink
                  css={css`
                    width: 10px;
                    margin-left: 5px;
                  `}
                />
              </a>
            </li>
          </NavSide>
        </nav>

        <button
          aria-label="メニューを開く"
          type="button"
          onClick={()=> {
            setSpnavOpen(!spnavOpen)
          }}
          css={css`
            pointer-events: auto;
            position: absolute;
            inset: 50% 0 auto auto;
            display: block;
            width: 16px;
            height: 14px;
            transform: translateY(-50%);
            ${mq.pc(css`
              display: none;
            `)}
            & > span {
              display: block;
              position: absolute;
              inset: auto 0;
              width: 16px;
              height: 2px;
              background: ${color.white};
              box-shadow: 0 0 2px ${csxColor(color.black).fade(0.5).toString()};
              transition: transform 0.3s, width 0.3s, top 0.3s, background-color 0.3s;
              &:nth-of-type(1) {
                top: 0;
              }
              &:nth-of-type(2) {
                top: 6px;
              }
              &:nth-of-type(3) {
                top: 12px;
              }
            }
            .spnavOpen & span {
              background: ${color.blue};
              box-shadow: 0 0 2px ${csxColor(color.white).fade(0.5).toString()};
              &:nth-of-type(1) {
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
              }
              &:nth-of-type(2) {
                width: 0px;
              }
              &:nth-of-type(3) {
                top: 50%;
                transform: translateY(-50%) rotate(135deg);
              }
            }
          `}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

      </header>
      <nav
        className={spnavOpen?"spnavOpen":""}
        css={css`
          position: fixed;
          inset: 0 0 0 auto;
          width: 200px;
          background: ${color.grayL};
          z-index: 999;
          color: ${color.black};
          font-family: ${fontFamily.serif};
          box-shadow: -2px 0 10px ${csxColor(color.blue).fade(0.1).toString()};
          overflow: auto;
          visibility: collapse;
          transform: translateX(100%);
          transition: transform 0.5s, visibility 0s 0.5s;
          &.spnavOpen {
            transition: transform 0.5s;
            visibility: visible;
            transform: translateX(0%);
          }
          & > ul {
            list-style: none;
            padding: 80px 0 40px 24px;
            & > li {
              flex: 1;
              & + li {
                margin-top: 16px;
              }
              & > a {
                text-decoration: none;
                svg path {
                  fill: ${color.black};
                }
              }
            }
          }
        `}
      >
        <ul>
          <li>
            <Link to="/about">山田屋について</Link>
          </li>
          <li>
            <Link to="/philosophy">島焼酎への想い</Link>
          </li>
          <li>
            <Link to="/company">会社概要</Link>
          </li>
          <li>
            <Link to="/recruit">採用情報</Link>
          </li>
          <li>
            <Link to="/contact">お問い合わせ</Link>
          </li>
          <li>
            <a href="https://www.shimarket.tokyo/" target="_blank" rel="noopener noreferrer">
              オンラインストア
              <ExternalLink
                css={css`
                  width: 10px;
                  margin-left: 5px;
                `}
              />
            </a>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Header
