import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { color, mq, width, widthFromGrid, fontFamily } from "../utils/styles"

import Layout from "../components/layout"
import SEO from "../components/common/seo"
import SubpageTitle from "../components/common/subpage-title"

const Title = styled.h4`
  font-family: ${fontFamily.serif};
  font-weight: bold;
  ${mq.pc(css`
    margin: 40px auto 0;
    width: ${widthFromGrid(8)}px;
    font-size: 2.4rem;
    line-height: 1.7;
  `)}
  ${mq.sp(css`
    margin: 32px auto 0;
    font-size: 1.4rem;
    line-height: 1.6;
  `)}
`

const Detail = styled.div`
  ${mq.pc(css`
    margin: 16px auto 0;
    width: ${widthFromGrid(8)}px;
  `)}
  ${mq.sp(css`
    margin: 16px auto 0;
  `)}
`

const Privacy: React.FC = () => {
  return (
    <Layout>
      <SEO title="プライバシーポリシー" />
      <SubpageTitle title="PRIVACY POLICY" />

      <section
        css={css`
          background: ${color.white};
          color: ${color.black};
          ${mq.pc(css`
            width: ${widthFromGrid()}px;
            margin: 80px auto 0;
            padding: 80px 0 120px;
          `)}
          ${mq.sp(css`
            max-width: ${width.spContentMax};
            width: 94%;
            margin: 48px auto 0;
            padding: 16px 8px 32px;
          `)}
        `}
      >
        <Detail>
          有限会社山田屋（以下，「当社」といいます。）は，当社の運営する本ウェブサイト
          8jo-yamadaya.co.jp
          上（以下,「当ウェブサイト」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「当ポリシー」といいます。）を定めます。
        </Detail>

        <Title>収集する利用者情報</Title>
        <Detail>
          当ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、
          その他ユーザーのスマートフォン、PC等の端末においてユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、
          当ポリシーに基づき当社が収集するものを意味するものとします。
        </Detail>

        <Title>アクセス解析ツールについて</Title>
        <Detail>
          当ウェブサイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。
          <br />
          この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関しての詳細はGoogleアナリティクスサービス利用規約のページやGoogleポリシーと規約ページをご覧ください。
        </Detail>

        <Title>他サイトからの埋め込みコンテンツ</Title>
        <Detail>
          当ウェブサイトに埋め込みコンテンツ (SNS、マップなど)
          が含まれます。他サイトからの埋め込みコンテンツは、訪問者がそのサイトを訪れた場合とまったく同じように振る舞います。
          <br />
          これらのサイトは、あなたのデータの収集、Cookie
          の使用、サードパーティによる追加トラッキングの埋め込み、埋め込みコンテンツとのやりとりの監視を行うことがあります。アカウントを使ってそのサイトにログイン中の場合、埋め込みコンテンツとのやりとりのトラッキングも含まれます。
        </Detail>

        <Title>免責事項</Title>
        <Detail>
          当ウェブサイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。
          <br />
          また当ウェブサイト内の情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。
          <br />
          当ウェブサイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
        </Detail>
      </section>
    </Layout>
  )
}

export default Privacy
