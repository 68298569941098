import React, { useEffect } from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import LinkButton from "../common/link-button"
import { H3, H5, mq, color, widthFromGrid } from "../../utils/styles"
import bgPaper from "../../img/top/japanese_paper.png"
import { ReactComponent as CafeCircle } from "../../img/top/cafe_circle.svg"
import Latte from "../../img/top/latte.png"

type SectionProps = {
  className?: string;
}
const Cafe: React.FC<SectionProps> = ({className}) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const animations = setAnimation()

    return () => {
      animations.map(animation => {
        animation.kill()
      })
    }
  }, [])

  const setAnimation = () => {
    const animations: { kill(): any }[] = []
    const latteTl = gsap.timeline({
      scrollTrigger: {
        trigger: "#top_latte",
        start: "top bottom",
        end: "top center",
        scrub: true,
      },
    })
    latteTl
      .fromTo(
        "#top_latte .latte_mask",
        {
          background: color.black,
          autoAlpha: 1,
        },
        { autoAlpha: 0 }
      )
      .fromTo("#top_latte", { autoAlpha: 0.9 }, { autoAlpha: 1 }, "<")
    animations.push(latteTl)

    const copyTl = gsap.fromTo(
      "#cafe_copy",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 2,
        scrollTrigger: {
          trigger: "#cafe_copy",
          start: "top 80%",
        },
      }
    )
    animations.push(copyTl)

    return animations
  }

  return (
    <section
      className={className}
      css={css`
        position: relative;
        ${mq.pc(css`
          width: ${widthFromGrid()}px;
          margin: 130px auto 0;
          padding: 72px 0 0;
        `)}
        ${mq.sp(css`
          overflow: hidden;
          margin: 80px auto 0;
          padding: 70px 0 min(71%, 343px);
        `)}
      `}
    >
      <CafeCircle
        css={css`
          position: absolute;
          top: 0;
          right: 0;
          animation: cafe_rotate linear 50s infinite;
          @keyframes cafe_rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(-359deg);
            }
          }
          ${mq.pc(css`
            width: 16.93%;
          `)}
          ${mq.sp(css`
            width: 80px;
            top: 40px;
            right: -35px;
            margin-right: 3%;
          `)}
        `}
      />
      <div
        css={css`
          position: relative;
          margin: 0 auto;
          background: top left / 100px url("${bgPaper}");
          ${mq.pc(css`
            width: 834px;
            padding: 17px 0 17px 26.39%;
          `)}
          ${mq.sp(css`
            width: 94%;
            padding: 17px 0 120px;
            display: grid;
            grid-template-columns: 1fr min(90%, 400px) 1fr;
          `)}
          &::before {
            content: "";
            display: block;
            position: absolute;
            inset: 8px;
            pointer-events: none;
            border: solid 1px ${color.pink};
          }
        `}
      >
        <H5
          css={css`
            background: ${color.blue};
            line-height: 1;
            padding: 8px;
            width: fit-content;
            ${mq.sp(css`
              grid-column: 2;
            `)}
          `}
        >
          イートインもテイクアウトも
        </H5>
        <H3
          id="cafe_copy"
          css={css`
            position: relative;
            color: ${color.blue};
            border-bottom: solid 1px ${color.blue};
            padding-bottom: 8px;
            ${mq.pc(css`
              width: fit-content;
            `)}
            ${mq.sp(css`
              grid-column: 2;
            `)}
          `}
        >
          八丈島の特産品を使った
          <br />
          オリジナルドリンク&amp;スイーツ
          <div className="cafe_mask" />
        </H3>


        <LinkButton
          css={css`
            margin: 16px auto 0;
            ${mq.sp(css`
              grid-column: 2;
              max-width: 100%;
            `)}
          `}
          to="/about?to=cafe"
        >
          カフェスペースについて
        </LinkButton>
      </div>
      <div
        id="top_latte"
        css={css`
          position: absolute;
          pointer-events: none;
          &::before {
            display: block;
            content: '';
            padding-top: 150.46%;
          }
          ${mq.pc(css`
            width: 32.17%;
            top: 0;
            left: 0;
          `)}
          ${mq.sp(css`
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 68.12%;
            max-width: 320px;
          `)}
        `}
      >
        <StaticImage
          placeholder="none"
          src="../../img/top/latte.png"
          css={css`
            position: absolute;
            inset: 0;
          `}
          aspectRatio={646 / 972}
          alt=""
        />
        <div
          className="latte_mask"
          css={css`
            position: absolute;
            inset: 0;
            mask: center center / 100% no-repeat url(${Latte});
          `}
        />
      </div>
    </section>
  )
}

export default Cafe
