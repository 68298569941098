import React from "react"
import { css } from "@emotion/react"
import { useFormContext } from "react-hook-form"

import { H4, color, mq } from "../../utils/styles"
import LinkButton from "../common/link-button"
import {
  FormSection,
  FormNote,
  FormWrap,
  labelStyle,
  labelRequieStyle,
} from "./form-parts"

const Sent: React.FC = () => {
  return (
    <FormSection>
      <FormWrap>
        <div
          css={css`
            width: fit-content;
            margin: 0 auto;
            ${mq.pc(css`
              grid-column: 1 / -1;
            `)}
            ${mq.sp(css``)}
          `}
        >
          <H4>メッセージを送信しました</H4>
          <p
            css={css`
              margin-top: 24px;
            `}
          >
            ※お問い合わせの内容によっては、返信にお時間がかかる場合がございます。
            <br />
            ※いただいたお問い合わせの内容によってはお答えしかねる場合もございます。あらかじめご了承ください。
          </p>
        </div>
        <div
          css={css`
            grid-column: 1 / -1;
            width: fit-content;
            margin: 32px auto 0;
          `}
        >
          <LinkButton to="/">トップページに戻る</LinkButton>
        </div>
      </FormWrap>
    </FormSection>
  )
}

export default Sent
