import React from "react"
import { css } from "@emotion/react"

const BusinessHourNote: React.FC = () => {
  return (
    <p
      css={css`
        display: none;
        margin-top: 8px;
        font-weight: bold;
      `}
    >
      ※8月中の日曜日は19:00までオープン
    </p>
  )
}

export default BusinessHourNote
