import React, {useEffect} from "react"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { color, fadeBg } from "../utils/styles"

import Layout from "../components/layout"
import SEO from "../components/common/seo"
import SubpageTitle from "../components/common/subpage-title"
import Intro from "../components/company/intro"
import Spec from "../components/company/spec"
import SubNavigation from "../components/common/sub-navigation"

const Company: React.FC = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const animations = setAnimation()
    return () => {
      animations.map(animation => {
        animation.kill()
      })
    }
  }, [])

  const setAnimation = () => {
    const animations: { kill(): any }[] = []

    let startPercent = "40%"
    const elmY = document.querySelector('.section_intro')?.getBoundingClientRect().top
    if (elmY) {
      startPercent = Math.round((elmY + window.scrollY) * 10000 / window.innerHeight) / 100 + "%"
    }
    animations.push(fadeBg('.section_intro', '.section_intro_bg', {start: `top ${startPercent}`}))
    animations.push(fadeBg('.section_spec', '.section_spec_bg'))

    return animations
  }

  return (
    <Layout>
      <SEO title="会社概要" />
      <div
        css={css`
          position: fixed;
          inset: 0;
          z-index: -1;
          pointer-events: none;
        `}
      >
        <StaticImage
          className="section_intro_bg"
          css={css`
            opacity: 0;
            position: absolute;
            inset: 0;
            background: ${color.baseBg};
            img {
              mix-blend-mode: multiply;
            }
          `}
          src="../img/common/section_bg_ohsaka.jpg" alt=""
        />
        <StaticImage
          className="section_spec_bg"
          css={css`
            opacity: 0;
            position: absolute;
            inset: 0;
            background: ${color.baseBg};
            img {
              mix-blend-mode: multiply;
            }
          `}
          src="../img/common/section_bg_borawazawa.jpg" alt=""
        />
      </div>

      <SubpageTitle title="会社概要">
        <StaticImage
          className="subpage_title_bg"
          src="../img/company/subpage_title.jpg"
          alt="会社概要"
        />
      </SubpageTitle>
      <Intro className="section_intro" />
      <Spec className="section_spec" />

      <SubNavigation />

    </Layout>
  )
}

export default Company
