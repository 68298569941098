import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { color } from "../../utils/styles"
import Logo from "../../img/top/hero_logo.png"

const Hero: React.FC = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const animations = setAnimation()

    scrollIconVisibility()
    document.addEventListener("scroll", scrollIconVisibility)

    return () => {
      animations.map(animation => {
        animation.kill()
      })
    }
  }, [])

  const setAnimation = () => {
    const animations: { kill(): any }[] = []
    animations.push(
      gsap.fromTo(
        "#hero_logo_start",
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 2,
          delay: 1,
          onComplete: () => {
            const elm = document.getElementById("hero_scroll")
            if (!elm) return
            elm.classList.add("animation_complate")
          },
        }
      )
    )
    const heroTl = gsap.timeline({
      scrollTrigger: {
        trigger: "#hero",
        start: "top top",
        end: `+=${window.innerHeight * 1.5}`,
        scrub: true,
        pin: true,
      },
    })
    heroTl
      .fromTo("#hero_bg", { opacity: 0 }, { opacity: 1 })
      .fromTo("#hero_logo", { opacity: 0 }, { opacity: 1 }, "<")
    animations.push(heroTl)

    return animations
  }

  const scrollIconVisibility = () => {
    const offset = 50
    const elm = document.getElementById("hero_scroll")
    if (!elm) return

    if (window.pageYOffset < offset) {
      elm.classList.add("show")
    } else {
      elm.classList.remove("show")
    }
  }

  return (
    <section
      css={css`
        position: realtive;
      `}
    >
      <div
        id="hero"
        css={css`
          position: realtive;
          height: 100vh;
        `}
      >
        <div id="hero_bg">
          <StaticImage
            src="../../img/top/hero.jpg"
            css={css`
              img {
                object-position: bottom center;
              }
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              width: var(--max-width);
              height: 100vh;
            `}
            alt=""
          />
        </div>
        <div id="hero_logo_start">
          <StaticImage
            src="../../img/top/hero.jpg"
            css={css`
              img {
                object-position: bottom center;
              }
              position: absolute;
              top: 0;
              left: 0;
              width: var(--max-width);
              height: 100vh;
              mask: center center / min(280px, 50%) no-repeat url(${Logo});
            `}
            alt=""
          />
        </div>
        <div
          id="hero_logo"
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: var(--max-width);
            height: 100vh;
          `}
        >
          <StaticImage
            src="../../img/top/hero_logo.png"
            placeholder="none"
            css={css`
              width: min(280px, 50%);
            `}
            alt="山田屋 東京諸島本格焼酎 Tokyo Craft Spirits"
          />
        </div>
        <div
          id="hero_scroll"
          css={css`
            will-change: opacity;
            position: absolute;
            inset: auto 0 10%;
            padding-bottom: 20px;
            margin: 0 auto;
            width: 40px;
            text-align: center;
            opacity: 0;
            transition: opacity 3s;
            &.show.animation_complate {
              animation: scrollBlink 3s infinite;
            }
            &::after {
              display: block;
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 40px;
              height: 40px;
              transform: rotate(45deg);
              border-right: 1px solid ${color.white};
              border-bottom: 1px solid ${color.white};
            }
            @keyframes scrollBlink {
              0% {
                opacity: 0.1;
              }
              50% {
                opacity: 0.8;
              }
              100% {
                opacity: 0.1;
              }
            }
          `}
        >
          scroll
        </div>
      </div>
    </section>
  )
}
export default Hero
