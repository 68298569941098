import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { H4, color, mq, width, widthFromGrid } from "../../utils/styles"
import LinkButton from "../common/link-button"

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq.pc(css`
    width: ${widthFromGrid(4)}px;
    padding: 16px 0;
  `)}
  ${mq.sp(css`
    max-width: ${width.spContentMax};
    width: 94%;
    padding: 40px 0;
  `)}
  h4 {
    color: ${color.blue};
  }
  h5 {
    color: ${color.pink};
  }
  a {
    margin-top: 32px;
  }
`
const SubNavigation: React.FC = () => {
  return (
    <div
      css={css`
        margin: 120px auto 0;
        ${mq.pc(css`
          display: grid;
          grid-template-columns: 1fr ${widthFromGrid()}px 1fr;
        `)}
        ${mq.sp(css`
        `)}
      `}
    >
      <div
        css={css`
          background: ${color.grayL};
          ${mq.pc(css`
            grid-column: 1 / 3;
            display: flex;
            justify-content: flex-end;
          `)}
          ${mq.sp(css`
          `)}
        `}
      >
      <ul
        css={css`
          list-style: none;
          display: flex;
          padding: 32px 0 40px;
          ${mq.pc(css`
            width: ${widthFromGrid()}px;
            justify-content: space-between;
          `)}
          ${mq.sp(css`
            align-items: center;
            flex-direction: column;
          `)}
          & > li + li {
            ${mq.pc(css`
                border-left: 1px solid ${color.blue};
            `)}
            ${mq.sp(css`
                border-top: 1px solid ${color.blue};
            `)}
          }
        `}
      >
        <ListItem>
          <H4>お問い合わせ</H4>
          <h5>Contact</h5>
          <LinkButton to="/contact">お問い合わせフォームへ</LinkButton>
        </ListItem>
        <ListItem>
          <H4>会社概要</H4>
          <h5>Company</h5>
          <LinkButton to="/company">会社概要へ</LinkButton>
        </ListItem>
        <ListItem>
          <H4>採用情報</H4>
          <h5>Recruit</h5>
          <LinkButton to="/recruit">採用情報へ</LinkButton>
        </ListItem>
      </ul>
      </div>
    </div>
  )
}

export default SubNavigation
