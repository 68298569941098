import React from "react"

const SurroundSpan:React.FC = ({children}) => {
  const surrounded = []

  const surroundWords = (elm:string) => {
    return elm.split('').map((word, i)=> <span key={i}>{word}</span>)
  }

  if (Array.isArray(children)) {
    children.forEach(elm=> {
      if (typeof elm === "string") {
        surrounded.push(surroundWords(elm))
      } else {
        surrounded.push(elm)
      }
    })
  } else if(typeof children === "string") {
    surrounded.push(surroundWords(children))
  }
  return (
    <>
      {surrounded}
    </>
  )
}

export default SurroundSpan
