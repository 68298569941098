import React, { useEffect } from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { H2, color, width, mq } from "../../utils/styles"
import LinkButton from "../common/link-button"
import { ReactComponent as Islands } from "../../img/common/islands.svg"
import Bins from "../../img/top/bins.png"

const Intro: React.FC = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const animations = setAnimation()

    return () => {
      animations.map(animation => {
        animation.kill()
      })
    }
  }, [])

  const setAnimation = () => {
    const animations: { kill(): any }[] = []
    const titleTl = gsap.fromTo(
      "#top_intro .intro_title",
      {
        autoAlpha: 0,
      },
      {
        scrollTrigger: {
          trigger: "#top_intro .intro_title",
          start: "top 80%",
        },
        duration: 2,
        autoAlpha: 1,
      }
    )
    animations.push(titleTl)
    const binsTl = gsap.timeline({
      scrollTrigger: {
        trigger: "#top_bins",
        start: "top 80%",
        end: "bottom center",
        scrub: true,
      },
    })
    binsTl
      .fromTo(
        "#top_bins .bins_mask",
        {
          background: color.black,
          autoAlpha: 1,
        },
        { autoAlpha: 0 }
      )
      .fromTo("#top_bins .bins_ref", { autoAlpha: 0 }, { autoAlpha: 1 }, "<")
    animations.push(binsTl)

    return animations
  }

  return (
    <section
      id="top_intro"
      css={css`
        position: relative;
        overflow: hidden;
      `}
    >
      <Islands
        css={css`
          width: var(--max-width);
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.1;
          z-index: -1;
          pointer-events: none;
        `}
      />
      <H2
        className="intro_title"
        css={css`
          ${width.baseText}
          text-align: center;
          margin: 0 auto;
          border-bottom: solid 1px ${color.white};
          ${mq.pc(css`
            margin-top: 80px;
            padding: 0 10px 24px;
          `)}
          ${mq.sp(css`
            margin-top: 100px;
            padding: 0 0 8px;
            max-width: ${width.spContentMax};
          `)}
        `}
      >
        1954年創業
        <br />
        八丈島の酒屋 山田屋
      </H2>
      <p
        css={css`
          ${width.baseText}
          text-align: justify;
          font-weight: normal;
          ${mq.pc(css`
            margin: 24px auto 0;
            line-height: 2;
          `)}
          ${mq.sp(css`
            margin: 16px auto 0;
            max-width: ${width.spContentMax};
          `)}
        `}
      >
        東京の南方287kmに位置する離島、八丈島。そんな離島の酒屋として、観光のお客様のお土産から島民のお客様まで広く楽しんでもらえる商品をセレクトしました。
        <br />
        八丈島の特産品である島焼酎やくさや、明日葉といった地のものを使用した商品の他にも、全国の美味しいローカルフードや世界の自然派ワインなど注目のアイテムも多数取り揃えています。
      </p>
      <LinkButton
        css={css`
          margin: 0 auto;
          ${mq.pc(css`
            margin-top: 40px;
          `)}
          ${mq.sp(css`
            margin-top: 32px;
          `)}
        `}
        to="/about"
      >
        山田屋について
      </LinkButton>
      <div
        id="top_bins"
        css={css`
          position: relative;
          width: 94%;
          max-width: 981px;
          margin: max(-30px, -2%) auto min(167px, 19%);
          pointer-events: none;
          &::before {
            content: '';
            display: block;
            padding-top: 33.95%;
          }
        `}
      >
        <StaticImage
          className="bins_ref"
          placeholder="none"
          src="../../img/top/bins_ref.png"
          css={css`
            position: absolute;
            inset: 99% 0 auto;
            width: 100%;
          `}
          alt=""
        />
        <StaticImage
          placeholder="none"
          src="../../img/top/bins.png"
          alt=""
          css={css`
            position: absolute;
            inset: 0;
          `}
        />
        <div
          className="bins_mask"
          css={css`
            position: absolute;
            inset: 0;
            mask: center center / 100% no-repeat url(${Bins});
          `}
        />
      </div>
    </section>
  )
}

export default Intro
