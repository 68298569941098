import React from "react"
import { css } from "@emotion/react"

import { color, mq } from "../../utils/styles"
import { FormSection } from "./form-parts"

const Sending: React.FC = () => {
  return (
    <FormSection>
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50vh;
          ${mq.pc(css`
            grid-column: 1 / -1;
          `)}
          ${mq.sp(css``)}
        `}
      >
        <div
          css={css`
            &,
            &:before,
            &:after {
              //border-radius: 50%;
              width: 10px;
              height: 10px;
              animation: sending 1.8s infinite ease-in-out;
            }
            & {
              margin-top: -30px;
              position: relative;
              text-indent: -9999em;
              animation-delay: -0.16s;
            }
            &:before,
            &:after {
              content: "";
              position: absolute;
              top: 0;
            }
            &:before {
              left: -25px;
              animation-delay: -0.32s;
            }
            &:after {
              left: 25px;
            }
            @keyframes sending {
              0%,
              80%,
              100% {
                color: ${color.white};
                box-shadow: 0 10px 0 -1.3em;
              }
              40% {
                color: ${color.white};
                box-shadow: 0 10px 0 0;
              }
            }
          `}
        >
          Sending...
        </div>
      </div>
    </FormSection>
  )
}

export default Sending
