import React from "react"
import { Link } from "gatsby"
import { css, SerializedStyles } from "@emotion/react"

import { mq, color } from "../../utils/styles"
import { ReactComponent as ExternalLink } from "../../img/common/ex_link.svg"

type LinkButtonProps = {
  to?: string
  className?: string
  external?: boolean
  button?: boolean
  type?: "button" | "submit" | "reset" | undefined
  onClick?: ()=>any
}
const LinkButton: React.FC<LinkButtonProps> = props => {
  const { to, children, className, external, button, ...etc } = props
  const style = css`
    color: ${color.white};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    line-height: 70px;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background: ${color.pink};
    border-radius: 35px;
    ${mq.pc(css`
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.8;
      }
    `)}
  `
  return (
    <>
      {(external && (
        <a
          href={to}
          css={style}
          className={className}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
          <ExternalLink
            css={css`
              width: 10px;
              margin-left: 5px;
            `}
          />
        </a>
      )) ||
        (button && (
          <button css={style} className={className} type={etc.type || "button"} onClick={etc.onClick}>
            {children}
          </button>
        )) || (
          <Link
            css={style}
            className={className}
            to={to||"#"}
            {...etc}
          >
            {children}
          </Link>
        )}
    </>
  )
}

export default LinkButton
