import React, { useEffect } from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { color, mq, fontFamily, width, widthFromGrid } from "../../utils/styles"

const Intro: React.FC = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const animations = setAnimation()
    return () => {
      animations.map(animation => {
        animation.kill()
      })
    }
  }, [])

  const setAnimation = () => {
    const animations: {kill():any}[] = []
    const introTl = gsap.timeline({
      scrollTrigger: {
        trigger: "#about_intro",
        start: "top top",
        end: `+=${window.innerHeight * 1}`,
        scrub: true,
        pin: true,
      }
    })
    introTl
      .fromTo(".about_intro_copy", { opacity: 0 }, { opacity: 1 })
      .fromTo(".about_intro_text", {
        maskImage: "linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 0%)",
      }, {
        maskImage: "linear-gradient(rgba(0, 0, 0, 1) , rgba(0, 0, 0, 1) 100%)",
      })
    animations.push(introTl)

    return animations
  }

  return (
    <div>
      <section
        id="about_intro"
        css={css`
          position: relative;
          height: 100vh;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-family: ${fontFamily.serif};
          background: ${color.baseBg};
          overflow: hidden;
        `}
      >
        <StaticImage
          className="about_intro_bg"
          css={css`
            position: absolute;
            pointer-events: none;
            mix-blend-mode: multiply;
            ${mq.pc(css`
              inset: 0;
            `)}
            ${mq.sp(css`
              inset: auto 0 0;
            `)}
          `}
          src="../../img/common/section_bg_borawazawa.jpg"
          alt=""
        />
        <h3
          className="about_intro_copy"
          css={css`
            position: relative;
            margin: 0 auto;
            font-size: 1.4rem;
            ${mq.pc(css`
              width: ${widthFromGrid()}px;
              &::before {
                content: "■";
                color: ${color.pink};
                margin-right: 0.5em;
              }
            `)}
            ${mq.sp(css`
                width: 94%;
                max-width: ${width.spContentMax};
                padding-left: 1em;
                border-left: solid 1px ${color.pink};
            `)}
          `}
        >
          八丈島の酒屋として、東京諸島の酒屋として、日本の酒屋として
        </h3>
        <p
          className="about_intro_text"
          css={css`
            position: relative;
            margin: 24px auto;
            font-weight: bold;
            line-height: 2;
            ${mq.pc(css`
              width: ${widthFromGrid()}px;
              font-size: 3.2rem;
            `)}
            ${mq.sp(css`
              width: 94%;
              max-width: ${width.spContentMax};
              font-size: 2rem;
            `)}
          `}
        >
          1954年創業、東京島酒専門店 山田屋。
          <br />
          観光のお客様から島民の皆様が楽しめるように、八丈島から東京諸島の他の島々、さらには全国のローカルフードを厳選。それぞれの土地の魅力を感じることのできる商品を取り揃えています。
        </p>
      </section>
    </div>
  )
}

export default Intro
