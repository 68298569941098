import React, { useEffect, useState } from "react"
import { Global, css } from "@emotion/react"
import { color, mq, fontFamily } from "../utils/styles"
import "ress"

import Footer from "../components/common/footer"
import Header from "../components/common/header"

const Layout: React.FC = ({ children }) => {
  let widthTmp = 0
  const fontSize = 16
  const lineHeight = 1.8

  const [spnavOpen, setSpnavOpen] = useState(false)

  const setMaxSizes = () => {
      const root = document.documentElement
      root.style.setProperty(`--max-width`, `${window.innerWidth}px`)
      root.style.setProperty(`--max-height`, `${window.innerHeight}px`)
  }
  const resetMaxWidth = () => {
    if (widthTmp !== window.innerWidth) {
      setMaxSizes()
      widthTmp = window.innerWidth
    }
  }
  useEffect(() => {
    widthTmp = window.innerWidth
    setMaxSizes()

    window.addEventListener(`resize`, resetMaxWidth)
    return () => {
      window.removeEventListener(`resize`, resetMaxWidth)
    }
  }, [])

  return (
    <>
      <Global
        styles={css`
          :root {
            --max-width: 50vw;
            --max-height: 50vh;
          }
          html {
            font-size: 62.5%;
          }
          body {
            font-size: ${fontSize}px;
            font-size: ${fontSize / 10}em;
            font-family: ${fontFamily.sans};
            color: ${color.font};
            line-height: ${lineHeight};
            letter-spacing: normal;
            background: ${color.baseBg};
          }
          img {
            max-width: 100%;
          }
          a {
            color: inherit;
          }
          ${mq.pc(css`
            .sp {
              display: none;
            }
          `)}
          ${mq.sp(css`
            .pc {
              display: none;
            }
          `)}
        `}
      />
      <Header
        spnavOpen={spnavOpen}
        setSpnavOpen={setSpnavOpen}
      />
        {children}
      <Footer />
    </>
  )
}

export default Layout
