import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"
import { animateScroll } from "react-scroll"

import Layout from "../components/layout"
import SEO from "../components/common/seo"
import SubpageTitle from "../components/common/subpage-title"
import Intro from "../components/about/intro"
import Islands from "../components/about/islands"
import LocalFood from "../components/about/local-food"
import Wine from "../components/about/wine"
import Cafe from "../components/about/cafe"
import Access from "../components/about/access"

const About: React.FC = () => {
  const offset = 80
  const urlParam = useLocation().search
  useEffect(() => {
    const target = document.querySelector("#about_cafe")
    if (target && urlParam.indexOf("to=cafe") !== -1) {
      window.setTimeout(()=> {
        animateScroll.scrollTo(
          window.pageYOffset + target.getBoundingClientRect().top - offset,
        )
      }, 500)
    }
  }, [])

  return (
    <Layout>
      <SEO title="山田屋について" description="1954年創業、東京島酒・自然派ワインの山田屋。観光のお客様から島民の皆様が楽しめるように、八丈島から東京諸島の他の島々、さらには全国のローカルフードを厳選。それぞれの土地の魅力を感じることのできる商品を取り揃えています。" />
      <SubpageTitle title="山田屋について">
        <StaticImage
          className="subpage_title_bg"
          src="../img/about/subpage_title.jpg"
          alt="山田屋について"
        />
      </SubpageTitle>

      <Intro />
      <Islands />
      <LocalFood />
      <Wine />
      <Cafe />
      <Access />
    </Layout>
  )
}

export default About
