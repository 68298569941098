import React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"

import {
  H4,
  color,
  mq,
  width,
  widthFromGrid,
} from "../../utils/styles"

type IntroProps = {
  className?: string
}
const Intro: React.FC<IntroProps> = (props) => {
  return (
    <section
      css={css`
        ${mq.pc(css`
          display: flex;
          justify-content: space-between;
          width: ${widthFromGrid()}px;
          margin: 144px auto 0;
        `)}
        ${mq.sp(css`
          max-width: ${width.spContentMax};
          width: 94%;
          margin: 62px auto 0;
        `)}
      `}
      {...props}
    >
      <StaticImage
        css={css`
          flex: 0 0 ${widthFromGrid(6)}px;
          order: 2;
        `}
        src="../../img/recruit/intro.jpg"
        alt="山田屋 外観"
        aspectRatio={494 / 335}
      />
      <div
        css={css`
          order: 1;
          flex: 0 0 ${widthFromGrid(6)}px;
        `}
      >
        <H4
          css={css`
            border-bottom: 1px solid ${color.pink};
            ${mq.pc(css`
              padding-bottom: 24px;
            `)}
            ${mq.sp(css`
              padding: 16px 0;
            `)}
          `}
        >
          ローカルの魅力を世界に、
          <br />
          世界の魅力をローカルに。
        </H4>
        <p
          css={css`
            ${mq.pc(css`
              margin-top: 24px;
            `)}
            ${mq.sp(css`
              max-width: ${width.spContentMax};
              margin-top: 16px;
            `)}
            }
          `}
        >
          酒屋の仕事は酒蔵が作った魅力ある商品たちを多くの人に橋渡しする黒子であると考えます。
          <br />
          離島の酒屋として東京諸島のお酒を世界に、世界の商品を八丈島の皆様につなげる仕事をしませんか？
        </p>
      </div>
    </section>
  )
}

export default Intro
