import { css, SerializedStyles } from "@emotion/react"
import styled from "@emotion/styled"
import { gsap } from "gsap"

export const breakPoint = 1020

const blue = `#223b66`
const black = `#121d31`
const white = `#fff`
const pink = `#d16e72`
const orange = `#EE8D1E`
const grayL = `#F2F2F3`
const gray = `#E6E7E9`
const font = white
const baseBg = `linear-gradient(
  to right,
  ${black} 20%,
  ${blue} 80%,
  ${black}
)`

export const color = {
  blue,
  black,
  white,
  pink,
  orange,
  gray,
  grayL,
  font,
  baseBg,
}

const sans = `"Noto Sans JP", "Helvetica Neue", Arial, YuGothic, "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif`
const serif = `"Noto Serif JP",游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif`
export const fontFamily = {
  sans,
  serif,
}

export const mq: {
  pc:(props: SerializedStyles) => SerializedStyles;
  sp:(props: SerializedStyles) => SerializedStyles;
} = {
  pc: (props) => {
      return css`
        @media (min-width: ${breakPoint}px) {
          ${props}
        }
      `
  },
  sp: (props) => {
      return css`
        @media (max-width: ${breakPoint - 1}px) {
          ${props}
        }
      `
  },
}

export const widthFromGrid = (gridNum:number = 12):number => {
  const gridWidth = 69
  const spaceWidth = 16
  return gridWidth * gridNum + spaceWidth * (gridNum - 1)
}

const baseText = css`
  ${mq.pc(css`width: 494px;`)}
  ${mq.sp(css`width: 94%;`)}
`
const spContentMax = '600px'

export const width = {
  spContentMax,
  baseText,
}

export const H2 = styled.h2`
  font-family: ${fontFamily.serif};
  font-weight: bold;
  ${mq.pc(css`
    font-size: 4.8rem;
    line-height: 1.6;
  `)}
  ${mq.sp(css`
    font-size: 3.2rem;
    line-height: 1.6;
  `)}
`

export const H3 = styled.h3`
  font-family: ${fontFamily.serif};
  font-weight: bold;
  ${mq.pc(css`
    font-size: 4.0rem;
    line-height: 1.6;
  `)}
  ${mq.sp(css`
    font-size: 2.4rem;
    line-height: 1.6;
  `)}
`

export const H4 = styled.h4`
  font-family: ${fontFamily.serif};
  font-weight: bold;
  ${mq.pc(css`
    font-size: 3.2rem;
    line-height: 1.6;
  `)}
  ${mq.sp(css`
    font-size: 2.0rem;
    line-height: 1.6;
  `)}
`

export const H5 = styled.h5`
  font-family: ${fontFamily.serif};
  font-weight: bold;
  ${mq.pc(css`
    font-size: 2.4rem;
    line-height: 1.7;
  `)}
  ${mq.sp(css`
    font-size: 1.4rem;
    line-height: 1.6;
  `)}
`

export const P = styled.p`
  font-size: 1.6rem;
  line-height: 1.6;
`

export const specTableStyle = css`
  width: 100%;
  ${mq.pc(css`
    border-collapse: collapse;
  `)}
  ${mq.sp(css`
    &,
    & > tbody,
    & > tbody > tr,
    & > tbody > tr > th,
    & > tbody > tr > td {
      display: block;
    }
  `)}
  & > tbody > tr {
      & > th,
      & > td {
        padding: 32px 16px 8px;
      }
      & > th {
        border-bottom: solid 3px ${color.white};
        width: ${widthFromGrid(3)}px;
        text-align: left;
        vertical-align: top;
      }
      & > td {
        border-bottom: solid 1px ${color.white};
        ${mq.sp(css`
          padding-top: 16px;
        `)}
      }
    }
  }
`

export const fadeBg = (trigger: string, target: string, options:{} = {}): { kill(): any }=> {
  const defaultOptions = {
    trigger: trigger,
    start: "top 90%",
    end: "bottom 20%",
    scrub: true,
  }

  const timelineOptions = { ...defaultOptions, ...options }

  const result = gsap.timeline({
    scrollTrigger: timelineOptions
  })
  result
    .fromTo(target, { autoAlpha: 0 }, { autoAlpha: 1 })
    .to(target, {})
    .to(target, { autoAlpha: 0 })

  return result
}


