import React from "react"
import { css } from "@emotion/react"

const TwitterWidget: React.FC = () => {
  return (
    <>
      <div css={css`
        max-width: 500px;
        width: 94%;
        height: 540px;
        margin: 120px auto 0;
        `} >
      <a
        className="twitter-timeline"
        data-height="540"
        href="https://twitter.com/yamadaya8jo?ref_src=twsrc%5Etfw"
      >
        Tweets by yamadaya8jo
      </a>
      <script
        async
        src="https://platform.twitter.com/widgets.js"
        charSet="utf-8"
      ></script>
      </div>
    </>
  )
}

export default TwitterWidget
