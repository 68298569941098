import React from "react"
import { css } from "@emotion/react"
import { useFormContext } from "react-hook-form"

import { color, mq } from "../../utils/styles"
import LinkButton from "../common/link-button"
import {FormSection, FormNote, FormWrap, labelStyle, labelRequieStyle} from "./form-parts"

const confirmStyle = css`
  padding-top: 12px;
`

const Confirm: React.FC<{backToForm: ()=>void}> = ({backToForm}) => {
  const { getValues } = useFormContext()
  const values = getValues()
  const nl2br = (str: string) => {
    if (!str) return
    const regex = /([\n\r])/g
    return str.split(regex).map((line: string, i) => {
      return line.match(regex) ? <br key={i} /> : line
    })
  }

  return (
    <FormSection>

      <FormNote />
      <FormWrap>
        <h6 css={[labelStyle, labelRequieStyle]}>
          お名前
        </h6>
        <div css={confirmStyle}>
          {values.name}
        </div>

        <h6 css={[labelStyle, labelRequieStyle]}>
          メールアドレス
        </h6>
        <div css={confirmStyle}>
          {values.email}
        </div>

        <h6 css={[labelStyle, labelRequieStyle]}>
          お問い合わせ内容
        </h6>
        <div css={confirmStyle}>
          {nl2br(values.message)}
        </div>

        <div
          css={css`
            margin: 32px auto 0;
            width: fit-content;
            ${mq.pc(css`
                grid-column: 1 / -1
            `)}
          `}
        >
            <div
              css={css`
                position: relative;
                display: flex;
                align-items: center;
                width: 260px;
                font-weight: bold;
                &::before {
                  display: inline-block;
                  content: "";
                  width: 16px;
                  height: 16px;
                  border: solid 1px ${color.black};
                  margin: 2px 0.8em 0 0;
                }
                &::after {
                  display: block;
                  content: "";
                  position: absolute;
                  top: 45%;
                  left: 0;
                  width: 15px;
                  height: 10px;
                  border-left: 3px solid ${color.pink};
                  border-bottom: 3px solid ${color.pink};
                  transform-origin: 0 0;
                  transform: rotate(-45deg);
                  transition: height 0.2s, width 0.1s 0.1s;
                }
              `}
            >
              <span>個人情報の取り扱いに同意する</span>
            </div>
        </div>

        <div
          css={css`
            display: flex;
            justify-content: center;
            margin-top: 80px;
            ${mq.pc(css`
              grid-column: 1 / -1;
              button + button {
                margin-left: 32px;
              }
            `)}
            ${mq.sp(css`
              align-items: center;
              flex-direction: column;
              button + button {
                margin-top: 32px;
              }
            `)}
          `}
        >
          <LinkButton button type="reset" onClick={backToForm} css={css`background: ${color.gray}; color: ${color.black};`}>戻る</LinkButton>
          <LinkButton button type="submit">送信</LinkButton>
        </div>
      </FormWrap>
    </FormSection>
  )
}

export default Confirm
