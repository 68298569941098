import React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import LinkButton from "../common/link-button"

import { fontFamily, color, mq, width, widthFromGrid } from "../../utils/styles"

const Shimarket: React.FC = () => {
  return (
    <div
      css={css`
        max-width: ${widthFromGrid()}px;
        ${mq.pc(css`
          margin: 140px auto 0;
          display: flex;
        `)}
        ${mq.sp(css`
          margin: 120px auto 0;
          max-width: ${width.spContentMax};
        `)}
      `}
    >
      <div
        css={css`
            ${mq.pc(css`
              flex: 0 0 579px;
            `)}
            ${mq.sp(css`
              width: 100%;
            `)}
          `}
      >
        <StaticImage
          src="../../img/common/shimarket_thumb.png"
          alt="しまーけっと東京"
          width={579}
          aspectRatio={597/391}
          css={css`
            ${mq.pc(css`
              height: 100%;
            `)}
            ${mq.sp(css`
              width: 100%;
            `)}
          `}
        />
      </div>
      <div
        css={css`
          background: ${color.white};
          ${mq.pc(css`
            padding: 32px 16px;
          `)}
          ${mq.sp(css`
            padding: 2vw 2vw 4vw;
            width: 94%;
            margin: 0 auto;
          `)}
        `}
      >
        <h5
          css={css`
            background: ${color.orange};
            font-family: ${fontFamily.serif};
            font-weight: bold;
            line-height: 1;
            padding: 8px 16px;
            width: fit-content;
          `}
        >
          オンラインストア
        </h5>
        <h4
          css={css`
            font-weight: bold;
            color: ${color.black};
            margin-top: 8px;
          `}
        >
          東京都の離島の島酒・うまいもんが揃ってる
        </h4>
        <h3>
          <StaticImage
            src="../../img/common/shimarket_title.png"
            alt="しまーけっと東京"
            width={350}
            aspectRatio={350 / 48.43}
          />
        </h3>
        <p
          css={css`
            color: ${color.black};
            ${mq.pc(css`
              margin-top: 24px;
            `)}
            ${mq.sp(css`
              margin-top: 16px;
            `)}
          `}
        >
          島で食べたあの味にまた会いたい！噂の島の味を食べてみたい！そんな声にお応えした八丈島の酒類問屋が運営する東京諸島のうまいもの自慢
          しまーけっと東京。
        </p>
        <LinkButton
          to="https://www.shimarket.tokyo/"
          external
          css={css`
            margin: 16px auto 0;
          `}
        >
          しまーけっと東京
        </LinkButton>
      </div>
    </div>
  )
}

export default Shimarket
