import React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"

import { color, mq, width, widthFromGrid } from "../../utils/styles"

import GoogleMap from "../common/google-map"
import LinkButton from "../common/link-button"
import BusinessHourNote from "../common/business-hour-note"

const Access: React.FC = () => {
  return (
    <section
      css={css`
        background: ${color.white};
        color: ${color.blue};
        ${mq.pc(css`
          margin-top: 240px;
          padding: 64px 0 80px;
        `)}
        ${mq.sp(css`
          margin-top: 160px;
          padding: 32px 0 64px;
        `)}
      `}
    >
      <div
        css={css`
          margin: 0 auto;
          ${mq.pc(css`
            width: ${widthFromGrid()}px;
          `)}
          ${mq.sp(css`
            width: 94%;
            max-width: ${width.spContentMax};
          `)}
        `}
      >
        <GoogleMap />
        <div
          css={css`
            ${mq.pc(css`
              display: flex;
              justify-content: space-between;
              margin-top: 32px;
            `)}
            ${mq.sp(css`
              margin-top: 16px;
            `)}
          `}
        >
          <div
            css={css`
              ${mq.pc(css`
                order: 2;
                width: ${widthFromGrid(7)}px;
              `)}
            `}
          >
            <StaticImage
              src="../../img/about/access_image.jpg"
              alt="山田屋外観"
            />
          </div>
          <div
            css={css`
              ${mq.pc(css`
                width: ${widthFromGrid(4)}px;
              `)}
              ${mq.sp(css`
                width: fit-content;
                margin: 24px auto 0;
              `)}
            `}
          >
            <p>
              〒100-1511
              <br />
              東京都八丈島八丈町三根1952-1
              <br />
              有限会社 山田屋
              <br />
              営業日時：火〜日 10:00～19:00
              <br />
              定休日　：月
            </p>
            <BusinessHourNote />
            <LinkButton
              css={css`
                margin: 32px auto 0;
              `}
              to="https://g.page/8jo-yamadaya?share"
              external
            >
              Googleマップを開く
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Access
