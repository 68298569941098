import React from "react"
import Helmet from "react-helmet"

import { color } from "../../utils/styles"

type SEOProps = {
  title?: string
  description?: string
}

const SEO: React.FC<SEOProps> = ({ title, description }) => {
  const defaultTitle = `東京島酒・自然派ワインの山田屋 八丈島`
  const defaultDescription = `1954年創業。八丈島の酒屋 山田屋。八丈特産の島焼酎やくさや、明日葉を使用した商品はもちろん、世界の自然派ワインや全国の美味しいローカルフードやなど、観光のお土産から島民のお客様まで広く楽しんでもらえる商品をセレクトしました。`

  const titleSuffix = `東京島酒・自然派ワインの山田屋 八丈島`

  const siteTitle = title ? title + ` | ` + titleSuffix : defaultTitle
  const siteDescription = description ? description : defaultDescription

  const siteURL = process.env.GATSBY_URL || "/"

  return (
    <Helmet>
      <html lang="ja" />
      <title>{siteTitle}</title>
      <meta name="description" content={siteDescription} />

      <link rel="icon" href="/favicon.svg" />
      <link rel="icon alternate" type="image/png" href="/icon-192x192.png" />
      <link rel="apple-touch-icon" type="image/png" href="/apple-touch-icon-180x180.png" />

      <meta name="theme-color" content={color.blue} />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDescription} />
      <meta name="og:image" content={`${siteURL}/img/common/og_image.png`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={siteDescription} />
      <meta name="twitter:title" content={siteTitle} />
    </Helmet>
  )
}
export default SEO
