import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { mq, color, fadeBg } from "../utils/styles"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Layout from "../components/layout"
import SEO from "../components/common/seo"
import Hero from "../components/top/hero"
import Intro from "../components/top/intro"
import Cafe from "../components/top/cafe"
import Wine from "../components/top/wine"
import Shimarket from "../components/common/shimarket"
import GoogleMap from "../components/common/google-map"
import TwitterWidget from "../components/common/twitter-widget"
import SubNavigation from "../components/common/sub-navigation"

const Home: React.FC = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const animations = setAnimation()

    return () => {
      animations.map(animation => {
        animation.kill()
      })
    }
  }, [])

  const setAnimation = () => {
    const animations: { kill(): any }[] = []

    animations.push(gsap.to(
      '.init_mask',
      {autoAlpha: 0, duration: 0.3}
    ))

    animations.push(fadeBg('.section_cafe', '.section_cafe_bg'))
    animations.push(fadeBg('.section_others', '.section_others_bg'))

    gsap.fromTo(
      "#gnav",
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        delay: 0,
        scrollTrigger: {
          trigger: ".hero_wrap",
          start: "bottom 80%",
          toggleActions: "play none reverse none",
        },
      }
    )

    return animations
  }

  return (
    <Layout>
      <SEO />
      <div
        className="init_mask"
        css={css`
          position: fixed;
          inset: 0;
          background: ${color.baseBg};
          z-index: 2000;
          pointer-events: none;
        `}
      />

      <div className="hero_wrap">
        <Hero />
      </div>
      <Intro />
      <div className="section_cafe">
        <Cafe />
        <Wine />
      </div>
      <div className="section_others">
        <Shimarket />
        <GoogleMap
          css={css`
            display: block;
            ${mq.pc(css`
              margin-top: 140px;
            `)}
            ${mq.sp(css`
              margin: 120px auto 0;
              width: 94%;
            `)}
          `}
        />
        <TwitterWidget />
        <SubNavigation />
      </div>
      <div
        css={css`
          position: fixed;
          inset: 0;
          z-index: -1;
          pointer-events: none;
        `}
      >
        <StaticImage
          className="section_cafe_bg"
          css={css`
            position: absolute;
            inset: 0;
            background: ${color.baseBg};
            img {
              mix-blend-mode: multiply;
            }
          `}
          src="../img/common/section_bg_ohsaka.jpg" alt=""
        />
        <StaticImage
          className="section_others_bg"
          css={css`
            position: absolute;
            inset: 0;
            background: ${color.baseBg};
            img {
              mix-blend-mode: multiply;
            }
          `}
          src="../img/common/section_bg_borawazawa.jpg" alt=""
        />
      </div>
    </Layout>
  )
}

export default Home
