import React from "react"
import { css } from "@emotion/react"

import {
  H4,
  color,
  mq,
  width,
  widthFromGrid,
  specTableStyle,
} from "../../utils/styles"

type Props = {
  className?: string
}
const Spec: React.FC<Props> = props => {
  return (
    <section
      css={css`
        ${mq.pc(css`
          margin: 192px auto 0;
          width: ${widthFromGrid()}px;
        `)}
        ${mq.sp(css`
          max-width: ${width.spContentMax};
          width: 94%;
          margin: 128px auto 0;
        `)}
      `}
      {...props}
    >
      <H4
        css={css`
          border-bottom: 1px solid ${color.pink};
          padding-bottom: 8px;
        `}
      >
        会社基本データ
      </H4>

      <table
        css={[
          specTableStyle,
          css`
            ${mq.pc(css`
              margin-top: 72px;
            `)}
            ${mq.sp(css`
              margin-top: 40px;
            `)}
          `,
        ]}
      >
        <tbody>
          <tr>
            <th>社名</th>
            <td>有限会社 山田屋</td>
          </tr>
          <tr>
            <th>代表取締役</th>
            <td>山田　達人</td>
          </tr>
          <tr>
            <th>専務取締役</th>
            <td>山田　裕美子</td>
          </tr>
          <tr>
            <th>店舗統括責任者</th>
            <td>矢島　寛大</td>
          </tr>
          <tr>
            <th>事業内容</th>
            <td>総合酒類販売、ローカルフードショップ</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>東京都八丈島八丈町三根1952-1</td>
          </tr>
          <tr>
            <th>創業</th>
            <td>1954年6月　山田耕司商店　創業</td>
          </tr>
          <tr>
            <th>設立</th>
            <td>1997年2月13日　有限会社山田屋　設立</td>
          </tr>
          <tr>
            <th>資本金</th>
            <td>300万円</td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

export default Spec
