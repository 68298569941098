import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { animateScroll } from "react-scroll"

import Layout from "../components/layout"
import SEO from "../components/common/seo"
import SubpageTitle from "../components/common/subpage-title"
import Form from "../components/contact/form"
import Confirm from "../components/contact/confirm"
import Sending from "../components/contact/sending"
import Sent from "../components/contact/sent"

interface IFormValues {
  name: string
  email: string
  message: string
  agreement: boolean
}

type PageStateType = "form" | "confirm" | "sending" | "sent"

const Contact: React.FC = () => {
  const methods = useForm<IFormValues>()
  const { handleSubmit, reset } = methods

  const [pageState, setPageState] = useState<PageStateType>("form")
  const [isSendError, setIsSendError] = useState(false)

  const onSubmit = (data: IFormValues) => {
    switch (pageState) {
      case "form":
        setIsSendError(false)
        setPageState("confirm")
        animateScroll.scrollToTop({ duration: 300 })
        break
      case "confirm":
        setPageState("sending")
        sendFormData(data)
        break
    }
  }

  const apiURL = process.env.GATSBY_API_URL || ""
  const sendFormData = (data: IFormValues) => {
    fetch(`${apiURL}/api/contact/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response is not ok.`)
        }
        return response.json()
      })
      .then(data => {
        if (process.env.GATSBY_ENV === `develop`) {
          console.log(data)
        }
        if (!data.mailStatus.status) {
          throw new Error(`Message send failure.`)
        }
        setPageState("sent")
        reset()
        animateScroll.scrollToTop({ duration: 300 })
      })
      .catch(error => {
        if (process.env.GATSBY_ENV === `develop`) {
          console.log(
            "There has been a problem with your send operation:",
            error
          )
        }
        setIsSendError(true)
        setPageState("form")
        animateScroll.scrollToTop({ duration: 300 })
      })
  }

  return (
    <Layout>
      <SEO title="お問い合わせ" />
      <SubpageTitle title="お問い合わせフォーム" />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {pageState === "form" && <Form isSendError={isSendError} />}
          {pageState === "confirm" && (
            <Confirm
              backToForm={() => {
                setPageState("form")
                animateScroll.scrollToTop({ duration: 300 })
              }}
            />
          )}
        </form>
        {pageState === "sending" && <Sending />}
        {pageState === "sent" && <Sent />}
      </FormProvider>
    </Layout>
  )
}
export default Contact
