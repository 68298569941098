import React, { useEffect } from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { H3, color, width, widthFromGrid, mq } from "../../utils/styles"
import LinkButton from "../common/link-button"

const Wine: React.FC = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const animations = setAnimation()

    return () => {
      animations.map(animation => {
        animation.kill()
      })
    }
  }, [])

  const setAnimation = () => {
    const animations: { kill(): any }[] = []

    const bgTl = gsap.fromTo(
      "#top_wine .wine_bg",
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        scrollTrigger: {
          trigger: "#top_wine",
          start: "top bottom",
          end: "top center",
          scrub: true,
        },
      }
    )
    animations.push(bgTl)

    const bgPlxTl = gsap.fromTo(
      "#top_wine .wine_bg",
      {
        y: -100,
      },
      {
        y: 100,
        scrollTrigger: {
          trigger: "#top_wine",
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
      }
    )
    animations.push(bgPlxTl)

    const copyTl = gsap.fromTo(
      "#top_wine .wine_copy",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 2,
        scrollTrigger: {
          trigger: "#top_wine .wine_copy",
          start: "top 80%",
        },
        onStart: ()=> {
          document.querySelector("#top_wine .wine_body")?.classList.add("active")
        },
      }
    )
    animations.push(copyTl)

    return animations
  }
  return (
    <div
      id="top_wine"
      css={css`
        position: relative;
        overflow: hidden;
        ${mq.pc(css`
          margin: 120px 0 0;
          padding: 110px 0;
        `)}
        ${mq.sp(css`
          margin: 80px 0 0;
          padding: 48px 0 80px;
        `)}
      `}
    >
      <StaticImage
        className="wine_bg"
        src="../../img/top/wine_bg.jpg"
        alt=""
        css={css`
          position: absolute;
          inset: -100px 0;
          pointer-events: none;
        `}
      />
      <div
        css={css`
          position: relative;
          margin: 0 auto;
          ${mq.pc(css`
            width: ${widthFromGrid()}px;
          `)}
          ${mq.sp(css`
            max-width: ${width.spContentMax};
            width: 94%;
          `)}
        `}
      >
        <H3
          className="wine_copy"
        >
          自然派ワイン生産者が教えてくれた
          <br />
          島焼酎への想い
        </H3>
        <div
          className="wine_body"
          css={css`
            position: relative;
            ${mq.pc(css`
              width: ${widthFromGrid(6)}px;
              margin-top: 24px;
              padding-top: 24px;
            `)}
            ${mq.sp(css`
              margin-top: 16px;
              padding-top: 16px;
            `)}
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              width: 0%;
              height: 1px;
              background: ${color.pink};
              transition: width 2s 1s;
            }
            &.active::before {
              width: 100%;
            }
          `}
        >
          <p>
            フランス自然派ワインの生産者の何気ない一言が、島焼酎との向き合い方を教えてくれました。
          </p>
          <LinkButton
            to="/philosophy"
            css={css`
              margin: 32px auto 0;
            `}
          >
            島焼酎への想い
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

export default Wine
