import React from "react"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"

import { H4, color, mq, width, widthFromGrid } from "../../utils/styles"

type VoiceContentProp = {
  type?: number
}
const VoiceContent: React.FC<VoiceContentProp> = ({ children, type }) => {
  return (
    <div
      css={css`
        ${mq.pc(css`
          margin-left: 16px;
        `)}
        ${mq.sp(css`
          margin-top: 24px;
        `)}
      `}
    >
      <h5
        css={css`
          font-weight: bold;
          font-size: 1.6rem;
          &::before {
            content: "■";
            color: ${color.pink};
            margin-right: 0.5em;
          }
        `}
      >
        {type === 1 && `勤務形態や仕事内容について`}
        {type === 2 && `スタッフから一言`}
      </h5>
      <p
        css={css`
          margin-top: 8px;
        `}
      >
        {children}
      </p>
    </div>
  )
}

type VoiceProps = {
  className?: string
}
const Voice: React.FC<VoiceProps> = props => {
  return (
    <section
      css={css`
        ${mq.pc(css`
          margin: 156px auto 0;
          width: ${widthFromGrid()}px;
        `)}
        ${mq.sp(css`
          max-width: ${width.spContentMax};
          width: 94%;
          margin: 128px auto 0;
        `)}
      `}
      {...props}
    >
      <H4
        css={css`
          border-bottom: 1px solid ${color.pink};
          padding-bottom: 8px;
        `}
      >
        スタッフの声
      </H4>
      <ul
        css={css`
          list-style: none;
          ${mq.pc(css`
            margin-top: 64px;
          `)}
          ${mq.sp(css`
            margin-top: 56px;
          `)}
          & > li {
            ${mq.pc(css`
              display: flex;
              & > * {
                flex: 0 0 ${widthFromGrid(4)}px;
              }
            `)}
            ${mq.sp(css``)}
            & + li {
              border-top: solid 1px ${color.white};
              ${mq.pc(css`
                margin-top: 24px;
                padding-top: 24px;
              `)}
              ${mq.sp(css`
                margin-top: 40px;
                padding-top: 40px;
              `)}
            }
          }
        `}
      >
        <li>
          <StaticImage
            css={css`
              align-self: start;
            `}
            src="../../img/recruit/staff_01.jpg"
            alt=""
            aspectRatio={326 / 254}
          />
          <VoiceContent type={1}>
            山田屋の小売店舗運営をトータルに任されています。
            <br />
            商品の選定から売り場の構成・POPの作成など現場のスタッフさんたちとコミュニケーションをとりながらお店作りをしています。
            <br />
            商品買い付けは離島のため出張を伴います。全国の展示会や生産者さんの元へ行き、実際に商品を見て・味わって探してくるのは本当に楽しいです。時にはフランスまでも足を延ばして畑を見て生産者と会い、その商品を深堀りしています。
          </VoiceContent>
          <VoiceContent type={2}>
            有名な商品を仕入れてただ販売するのではなく、小さな生産者の声を拾いとって、お客様にわかりやすく魅力を伝える仕事にやりがいを感じます。
            <br />
            実際に生産者の方々とお会いしたり、社内で商品の情報共有をしたりといった研修が豊富なため、自信を持って接客をすることができます。
          </VoiceContent>
        </li>
      </ul>
    </section>
  )
}

export default Voice
