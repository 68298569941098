import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { color, mq, width, widthFromGrid } from "../../utils/styles"

export const labelStyle = css`
  position: relative;
  display: block;
  padding-top: 12px;
  font-weight: bold;
  font-size: 1.6rem;
  ${mq.pc(css`
    padding-left: 48px;
  `)}
  ${mq.sp(css`
    display: flex;
    align-items: center;
    * + & {
      margin-top: 24px;
    }
  `)}
`

export const labelRequieStyle = css`
  &::before {
    content: "必須";
    display: inline-block;
    background: ${color.pink};
    color: ${color.white};
    font-weight: bold;
    font-size: 1rem;
    line-height: 20px;
    width: 36px;
    text-align: center;
    border-radius: 4px;
    ${mq.pc(css`
      position: absolute;
      top: 16px;
      left: 0;
    `)}
    ${mq.sp(css`
      margin-right: 8px;
    `)}
  }
`

export const FormSection = styled.section`
  ${mq.pc(css`
    width: ${widthFromGrid()}px;
    margin: 80px auto 0;
  `)}
  ${mq.sp(css`
    max-width: ${width.spContentMax};
    width: 94%;
    margin: 48px auto 0;
  `)}
`

export const FormNote = ()=> {
  return (
      <p
        css={css`
          width: fit-content;
          margin: 0 auto;
        `}
      >
        ※お問い合わせの内容によっては、返信にお時間がかかる場合がございます。
        <br />
        ※いただいたお問い合わせの内容によってはお答えしかねる場合もございます。あらかじめご了承ください。
      </p>
  )
}

export const FormWrap:React.FC = ({children}) => {
  return (
      <div
        css={css`
          background: ${color.white};
          color: ${color.black};
          ${mq.pc(css`
            margin-top: 40px;
            padding: 80px 0 120px;
          `)}
          ${mq.sp(css`
            margin-top: 32px;
            padding: 16px 8px 32px;
          `)}
        `}
      >
        <div
          css={css`
            max-width: ${widthFromGrid(8)}px;
            margin: 0 auto;
            ${mq.pc(css`
              display: grid;
              grid-template-columns: 195px 453px;
              grid-template-rows: auto;
              gap: 16px;
            `)}
          `}
        >
          {children}
        </div>
      </div>
  )
}
